import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { generateBrandedOemSearchPath } from '../../../utils/urlHelpers/oem';
import './styles.css';
import ImageWithErrorHandler from '../../../components/ImageWithErrorHandler';
import { setImageUrl, LOGO_SIZES } from '../../../utils/listingHelper';

const HeroImage = (props) => {
  let { logo = '', heroImage, name } = useSelector((state) =>
    get(state, 'app.data.brandShowcase', {})
  );
  const makeModel = useSelector((state) =>
    get(state, 'app.data.makeModel', '')
  );

  if (!(logo && heroImage && name)) {
    return null;
  }

  const logoUrl = setImageUrl({
    imageUrl: logo,
    resizerImageParams: {
      width: LOGO_SIZES.brands.w,
      height: LOGO_SIZES.brands.h
    }
  });

  const linkURL = generateBrandedOemSearchPath(
    {},
    { brand: name.toLowerCase().replace(/\s+/g, '-') },
    true
  );

  return (
    <a href={linkURL}>
      <div
        className={classnames('paying-brand-hero-image', {
          mobile: props.isMobile
        })}
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="paying-brand-logo">
          {logoUrl && <ImageWithErrorHandler
                src={logoUrl}
                alt={`${makeModel} logo`}
                noFallbackImage={true}
              />}
        </div>
      </div>
    </a>
  );
};

HeroImage.propTypes = {
  isMobile: PropTypes.bool
};

export default memo(HeroImage);
