import React, {useContext, useEffect, useMemo, useState} from 'react';
import {getBoatUrl} from '../../../../../utils/urlHelpers/boat';
import {PortalConfigContext} from '../../../../../config/portal';

import {
  BREAKPOINTS,
  getBreakpoint,
  isSafelyInitializedWithLayout,
  isTabletOrSmallLaptopResolution
} from '../../../../../utils/commonHelper';
import {Ad} from '@dmm/react-common-components';
import KevelAd from '../../../../../components/Ads/KevelAd';
import {ThreeColumnListing} from '../ThreeColumnListing';
import PropTypes from 'prop-types';
import {useFeatureFlags} from '../../../../../context/hooks/useFeatureFlags';
import {Cookies} from 'react-cookie';
import {useTPPServices} from '../../../../../tppServices/tppDIHooks';
import {getMessages} from '../../../../../tppServices/translations/messages';
import {SponsoredBoatListings} from './SponsoredBoatListings';
import {ListingsWithoutCarrousel} from './ListingsWithoutCarrousel';
import {ListingsWithCarrousel} from './ListingsWithCarrousel';
import {
  buildBasicThreeColumnListingProps,
  buildEnhancedListingProps,
  buildOemListingProps,
  buildSponsoredListingProps, listingInNewTab,
  listingIsEnhanced,
  listingIsOemModel,
  MAX_DESKTOP_ADS
} from '../../../../../utils/propsBuilder/listingPropsBuilder';
import {EXPRESS_BRIDGE, getExpressBridgeService} from '../../../../../tppServices/expressBridgeService';

const SRPMobileAds = ({adParams, id}) => {
  return (<div className="mobile-box" key={`mobile${id}`}>
    <Ad {...adParams} />
  </div>);
};

SRPMobileAds.propTypes = {
  adParams: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const ThreeColumnListings = ({
    sponsoredBoats,
    fuzzySponsoredBoats,
    listings,
    teaserRate,
    formatMessage: t,
    locale,
    openContactForm,
    adParams,
    tracking,
    setListingClick,
    userCurrency,
    cookies,
    isBranded,
  }) => {
  let desktopAdCount = 0;
  let tabletSmallLaptopAdCount = 0;
  let position = 0;
  let standardPosition = 0;
  const [device, setDevice] = useState('');
  const context = useContext(PortalConfigContext);
  const [shouldOpenInNewTab, setShouldOpenInNewTab] = useState(listingInNewTab(context, device));
  const {boatsConstants, tpp} = useTPPServices();
  const messages = getMessages();
  const expressBridge = getExpressBridgeService(tpp);
  expressBridge.shareWithExpress(EXPRESS_BRIDGE.LISTING_PARSABLE, true);
  const {
    LISTING_SPONSORED, LISTING_ENHANCED, LISTING_STANDARD, LISTING_MANUFACTURER, SPONSORED_CAROUSEL_LISTING, FIFTH_ROW_POSITION
  } = boatsConstants;
  const {featureFlagFuzzySponsored, featureFlagListingImageCarousel, featureFlagKevelAds, featureFlagHideRequestInfoButton} = useFeatureFlags(cookies);
  const enableFuzzySponsoredSearch = !!context.supports?.enableFuzzySponsoredSearch && featureFlagFuzzySponsored;
  const enableListingImageCarousel = !!context.supports?.enableListingImageCarousel && featureFlagListingImageCarousel;
  const hideRequestInfoButton = !!context.supports?.hideRequestInfoButton && featureFlagHideRequestInfoButton;
  const enableRandomizedSponsoredBoatsSearch = !!context.supports?.enableRandomizedSponsoredBoatsSearch;
  const enableSponsoredListingsCarrousel = !!context.supports?.enableSponsoredListingsCarousel;
  const isSearchMobileBoxAds = !!context.supports?.isSearchMobileBoxAds;
  const isKevelAdEnabled = !!context.supports?.isKevelAdEnabled && featureFlagKevelAds;
  const currency = userCurrency;
  const renderWithCarrousel = enableSponsoredListingsCarrousel && fuzzySponsoredBoats?.length > 0;

  const updateDevice = (breakpoint) => {
    setDevice(breakpoint);
    setShouldOpenInNewTab(listingInNewTab(context, breakpoint));
  };

  // Set initial breakpoint for correct device layout
  useEffect(() => {
    const breakpoint = isTabletOrSmallLaptopResolution()
      ? BREAKPOINTS.tabletOrSmallLaptop
      : getBreakpoint();
    updateDevice(breakpoint);
  }, []);

  // Attach event listener to detect resize event
  useEffect(() => {
    const handleResize = () => {
      const breakpoint = isTabletOrSmallLaptopResolution()
        ? BREAKPOINTS.tabletOrSmallLaptop
        : getBreakpoint();
      updateDevice(breakpoint);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const supportsMonthlyPayment = useMemo(() => !!context.languages[locale]?.supports?.monthlyPayment, []);
  const msgsMonthlyPayment = useMemo(() => messages.boatLoansContent.monthlyPaymentTooltip, []);
  const isDesktop = useMemo(() => isSafelyInitializedWithLayout('desktop'), []);
  const translations = {t, messages, msgsMonthlyPayment};

  const preparePropsDependencies = (listing, position, currency = '') => {
    const priceProps = {currency, teaserRate, supportsMonthlyPayment};
    translations.priceSuffix = !isDesktop ? '*' : '';
    const propFlags = {enableListingImageCarousel, isBranded, shouldOpenInNewTab, hideRequestInfoButton};
    const makeDependencies = {device, tracking, locale, translations, position};
    const actions = {onClickRequestInfo, trackClick, onOpenContact};
    return {priceProps, propFlags, makeDependencies, actions};
  };

  const prepareListingProps = (listing, position, currency = '') => {
    const {priceProps, propFlags, makeDependencies, actions} = preparePropsDependencies(listing, position, currency);
    if (listingIsEnhanced(listing, LISTING_ENHANCED)) {
      makeDependencies.defaultType = LISTING_ENHANCED;
      const enhancedProps = buildEnhancedListingProps(listing, context, actions, makeDependencies, propFlags, priceProps);
      enhancedProps.targetBlank = shouldOpenInNewTab;
      return enhancedProps;
    } else if (listingIsOemModel(listing, LISTING_MANUFACTURER)) {
      makeDependencies.defaultType = LISTING_MANUFACTURER;
      const oemProps = buildOemListingProps(listing, context, actions, makeDependencies, propFlags, priceProps);
      oemProps.targetBlank = shouldOpenInNewTab;
      return oemProps;
    }
    const standardListing = buildBasicThreeColumnListingProps(listing, context, makeDependencies, propFlags, priceProps, actions);
    standardListing.type = LISTING_STANDARD;
    standardListing.targetBlank = shouldOpenInNewTab;
    return standardListing;
  };

  const appendThreeColListing = (listingArr, listing, position, standardPosition, index, currency = '',) => {
    if (
      desktopAdCount < MAX_DESKTOP_ADS &&
      (index === 4 || (index - 4) % 5 === 0)
    ) {
      let adSlot = adParams[`${'inlineBox'}${(desktopAdCount += 1)}${'Params'}`];

      const isKevelAd = adSlot?.adId === 'div-gpt-ad-inline-box-2' && isKevelAdEnabled;

      listingArr.push(
        <span key={`desktop${listing.id}`} className="column-ads">
          {isKevelAd ? <KevelAd/> : <Ad {...adSlot} />}
        </span>
      );
      position += 1;
    }
    const listingProps = prepareListingProps(listing, position, currency);
    listingArr.push( <ThreeColumnListing key={listing.id} {...listingProps} position={position}/> );
    return listingArr;
  };

  const appendListingOnTabletOrSmallLaptop = (listingArr, listing, position, standardPosition, index, currency) => {
    if (index === 3 || index === 12) {
      let adSlot1 = adParams[`${'inlineBox'}${(tabletSmallLaptopAdCount += 1)}${'Params'}`];
      let adSlot2 = adParams[`${'inlineBox'}${(tabletSmallLaptopAdCount += 1)}${'Params'}`];
      listingArr.push(
        <span key={`desktop${listing.id}`} className="column-ads tablet-ads">
          <Ad {...adSlot1} />
          <Ad {...adSlot2} />
        </span>
      );
      position += 1;
    }
    const props = prepareListingProps(listing, position, currency);
    listingArr.push( <ThreeColumnListing key={listing.id} {...props} position={position}/> );
    return listingArr;
  };

  const appendMobileAdToListingList = (listingArr, listing, index) => {
    let mobileBoxParams = null;
    if (index === 3) {
      mobileBoxParams = adParams.mobileBox1Params;
    }

    if (index === 9) {
      mobileBoxParams = adParams.mobileBox2Params;
    }

    if (index === 14) {
      mobileBoxParams = adParams.mobileBox3Params;
    }
    if (mobileBoxParams && isSearchMobileBoxAds) {
      listingArr.push(<SRPMobileAds key={index} adParams={mobileBoxParams} id={listing.id}/>);
    }
    return listingArr;
  };

  const prepareFuzzySponsoredProps = (fuzzySponsoredBoat, position) => {
    position += 1;
    const { priceProps, propFlags, makeDependencies, actions } = preparePropsDependencies(fuzzySponsoredBoat, position, currency);
    propFlags.isExactMatchSponsored = false;
    makeDependencies.defaultType = LISTING_SPONSORED;
    makeDependencies.position = position;
    propFlags.debug = true;
    const fuzzyListingProps = buildSponsoredListingProps(fuzzySponsoredBoat, context, actions, makeDependencies, propFlags, priceProps);
    return fuzzyListingProps;
  };

  const createListingWithCarrouselChunk = (chunk, appendStickyAd) => {
    try {
      return chunk.reduce((listingArr, listing, index) => {
        position += 1;
        standardPosition += 1;
        if (device === BREAKPOINTS.tabletOrSmallLaptop) {
          return appendListingOnTabletOrSmallLaptop(listingArr, listing, position, standardPosition, index, currency);
        }
        const appendAd = appendStickyAd && !sponsoredBoats.length && index === 1;
        if (appendAd) {
          listingArr.push(
            <div key={`mobile${sponsoredBoats.id}`} className="sticky-mobile-ad">
              <Ad {...adParams.mobileLeaderboard1Params} />
            </div>
          );
        }
        appendMobileAdToListingList(listingArr, listing, position - 1);
        return appendThreeColListing(listingArr, listing, position, standardPosition, index, currency);
      }, []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in createListingWithCarrouselChunk', e);
      return [];
    }
  };

  const createListingsWithCarouselSponsoredChunk = (fuzzySponsoredBoatList, position) => {
    try {
      return fuzzySponsoredBoatList.map((fuzzySponsoredBoat) => {
        position += 1;
        const {
          priceProps,
          propFlags,
          makeDependencies,
          actions
        } = preparePropsDependencies(fuzzySponsoredBoatList, position, currency);
        propFlags.isExactMatchSponsored = false;
        makeDependencies.defaultType = SPONSORED_CAROUSEL_LISTING;
        makeDependencies.position = position;
        const fuzzyListingProps = buildSponsoredListingProps(fuzzySponsoredBoat, context, actions, makeDependencies, propFlags, priceProps);
        return (<ThreeColumnListing key={fuzzySponsoredBoat.id} {...fuzzyListingProps} position={position}/>);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in createListingsWithCarouselSponsoredChunk', e);
      return [];
    }
  };

  const onClickRequestInfo = (event, isExactMatchSponsored, listing) => {
    if (isExactMatchSponsored) {
      event.preventDefault();
      openContactForm(listing);
    } else {
      window.open(getBoatUrl(listing), shouldOpenInNewTab ? '_blank' : '_self');
    }
  };

  const trackClick = (listingId, listingType, region, make) => {
    return () => {
      setListingClick(listingId, listingType, region, make);
    };
  };

  const onOpenContact = (event, listing) => {
    event.preventDefault();
    openContactForm(listing);
  };
  const getSponsoredBoatsProps = () => {
    const priceProps = {currency, teaserRate, supportsMonthlyPayment};
    const propFlags = {enableListingImageCarousel, isBranded, shouldOpenInNewTab, hideRequestInfoButton};
    const makeDependencies = {device, tracking, locale, translations};
    const actions = {onClickRequestInfo, trackClick, onOpenContact};
    const type = LISTING_SPONSORED;
    return {
      sponsoredBoats, position, device, adParams, currency, tracking,
      priceProps, propFlags, makeDependencies, actions, type
    };
  };

  const prepareListingsWithCarrouselChunks = () => {
    const sliceBreakPoint = device === BREAKPOINTS.tabletOrSmallLaptop ? 9 : 10;
    const firstSlice = listings.slice(0, sliceBreakPoint);
    const secondSlice = listings.slice(sliceBreakPoint);
    const firstChunk = createListingWithCarrouselChunk(firstSlice, true);
    const secondChunk = createListingWithCarrouselChunk(secondSlice, false);
    const sponsoredListingCarouselChunk = createListingsWithCarouselSponsoredChunk(fuzzySponsoredBoats, position, standardPosition, setListingClick, tracking);
    return {firstChunk, secondChunk, sponsoredListingCarouselChunk};
  };

  const getListingWithCarrouselProps = () => {
    const chunks = prepareListingsWithCarrouselChunks();
    return {device, formatMessage: t, ...chunks};
  };

  const prepareFuzzySponsoredListings = () => {
    try {
    const fuzzySponsoredListings = fuzzySponsoredBoats.reduce((listingArr, fuzzySponsoredBoat) => {
      position += 1;
      standardPosition += 1;
      const inlineProps = prepareFuzzySponsoredProps(fuzzySponsoredBoat, position, standardPosition);
      listingArr.push(
        <ThreeColumnListing
          key={fuzzySponsoredBoat.id}
          {...inlineProps}
          position={position}/>
      );
      return listingArr;
    }, []);
    return fuzzySponsoredListings;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in prepareFuzzySponsoredListings', e);
      return [];
    }
  };

  const prepareListingsWithoutCarrousel = () => {
    try {
      return listings.reduce((listingArr, listing, index) => {
        if (standardPosition === FIFTH_ROW_POSITION && (enableFuzzySponsoredSearch || enableRandomizedSponsoredBoatsSearch) && fuzzySponsoredBoats?.length > 0) {
          const innerFuzzySponsoredListings = prepareFuzzySponsoredListings();
          listingArr = listingArr.concat(innerFuzzySponsoredListings);
        } else {
          position += 1;
          standardPosition += 1;
        }
        if (device === BREAKPOINTS.tabletOrSmallLaptop) {
          return appendListingOnTabletOrSmallLaptop( listingArr, listing, position, standardPosition, index, currency );
        }
        if (!sponsoredBoats.length && index === 1) {
          listingArr.push(
            <div key={`mobile${sponsoredBoats.id}`} className="sticky-mobile-ad" >
              <Ad {...adParams.mobileLeaderboard1Params} />
            </div>
          );
        }
        appendMobileAdToListingList(listingArr, listing, index);
        return appendThreeColListing(listingArr, listing, position, standardPosition, index, currency);
      }, []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in prepareListingsWithoutCarrousel', e);
      return [];
    }
  };
  // We could move most of the listing generation logic to the Listings components and make use of with useMemo
  // maybe next time we clean up and refactor...
  return (
    <>
      <SponsoredBoatListings {...getSponsoredBoatsProps()} />
      {!renderWithCarrousel && <ListingsWithoutCarrousel listings={prepareListingsWithoutCarrousel()}/>}
      {renderWithCarrousel && <ListingsWithCarrousel {...getListingWithCarrouselProps()} />}
    </>
  );
};

ThreeColumnListings.propTypes = {
  sponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fuzzySponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  listings: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  teaserRate: PropTypes.number,
  formatMessage: PropTypes.func,
  locale: PropTypes.string,
  openContactForm: PropTypes.func,
  adParams: PropTypes.object,
  setListingClick: PropTypes.func,
  tracking: PropTypes.shape({
    region: PropTypes.string,
    page: PropTypes.string,
    pageSize: PropTypes.string,
    setProductImpression: PropTypes.func
  }),
  userCurrency: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
  isBranded: PropTypes.bool,
  Carousel: PropTypes.object
};
