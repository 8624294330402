import React from 'react';
import PropTypes from 'prop-types';
import {THREE_COL_LISTINGS_CONTAINER} from '../../../../../utils/domParserHelper';

const ListingsWithoutCarrousel = ({listings}) => {
  return (
    <>
      <div className="container" data-testid={THREE_COL_LISTINGS_CONTAINER}>
        {listings}
      </div>
    </>
  );
};

ListingsWithoutCarrousel.propTypes = {
  listings: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export {ListingsWithoutCarrousel};
