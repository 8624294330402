import {
  getCarouselImageUrls,
  getListingLength,
  getListingSubdivision,
  getLocation,
  getPriceAmount,
  getPriceV2,
  getSellerName,
  getTitle,
  isListingEnhanced,
  listingAttribute as getListingAttribute,
  resolveMonthlyPrice,
  showPriceCalculation,
  setImageUrl,
  LOGO_SIZES
} from '../listingHelper';
import {BREAKPOINTS, getAssetUrl, isManufacturerListing} from '../commonHelper';
import {getBoatUrl, imageUrlWithDimensions} from '../urlHelpers/boat';
import {asArray, definedValue} from '../index';
import {isFinanceable} from '../trident';

export const MAX_DESKTOP_ADS = 4;
const MAX_NUMBER_OF_IMAGES = 5;
const MIN_NUMBER_OF_IMAGES = 2;

export const showPriceInfo = (listing) => {
  const enhanced = isListingEnhanced(listing);
  const isManufacturer = isManufacturerListing(listing.isOemModel);
  return enhanced || !isManufacturer;
};

export const showPrevPrice = (listing) =>
    showPriceInfo(listing) && !listing?.price?.hidden && listing?.previousPrice;


export const getTags = (listing) => {
  const { hasAttribute, attributeText } = getListingAttribute(listing);
  if (!hasAttribute) {
    return showPrevPrice(listing) ? [{ text: 'Price Drop' }] : [];
  }
  return [{ text: attributeText }];
};

export const getImageUrl = (listing, type) => {
  const listingMedia = asArray(listing?.media);
  const images = listingMedia.filter((media) => media.mediaType === 'image');
  const image = images[0];
  return image?.url ? imageUrlWithDimensions(image.url, image.date?.modified, type) : undefined;
};

export const getImageAlt = (listing, config) => {
  let listingAltText = getTitle(listing);
  const altTextVariant = config?.pages?.searchResults?.listingImage?.altText?.variant || 0;
  if (altTextVariant === 1) {
    listingAltText = `${listing.make} ${listing.model}`;
  }

  return listingAltText;
};

export const findListingPrice = (listing, currency) => {
  return showPriceInfo(listing)
        ? getPriceV2(listing, null, definedValue(currency, ''))
        : undefined;
};

export const showMonthlyPrice = (listing, supportsMonthlyPayment, teaserRate) => {
  const price = getPriceAmount(listing);
  const hiddenPrice =
    listing?.price?.hidden ||
    price <= 1;
  const financeable = isFinanceable(price, listing?.year);

  return (
    showPriceInfo(listing) &&
    !hiddenPrice &&
    supportsMonthlyPayment &&
    resolveMonthlyPrice(listing, teaserRate) &&
    financeable
  );
};

export const setCarouselProps = (listing, device, enableListingImageCarousel, isBranded) => {
  const { images, imageCount } = getCarouselImageUrls(listing, MAX_NUMBER_OF_IMAGES, MIN_NUMBER_OF_IMAGES);
  if (!enableListingImageCarousel || isBranded || images?.length < MIN_NUMBER_OF_IMAGES) {
    return {};
  }

  return {
    Carousel: {
      prevClick: /* istanbul ignore next */ (event) => {
        event.preventDefault();
      },
      nextClick: /* istanbul ignore next */ (event) => {
        event.preventDefault();
      },
      items: images,
      classNames: {
        indicator: true
      },
      hideLeftArrowButtonOnFirstSlide: true,
      showArrowButtonsOnHover: (device === BREAKPOINTS.mobile || device === BREAKPOINTS.tablet) ? false : true,
      cta: {
        text: `View all ${imageCount} photos`,

      },
      disableResizeHandler: true
    },
  };
};

export const getCPYBDataForListing = (listing, locale) => {
  if (locale !== 'us' || !listing.cpybLogo) {
    return {};
  }
  const imageLogo = getAssetUrl('/assets/images/cpyb-logo.svg');
  return {
    imageLogo,
    imageLogoAlt: 'cpyb-logo'
  };
};

export const calculateTerm = (listing) => {
  const price = getPriceAmount(listing);
  return price && price >= 50000 ? 240 : 180;
};

export const findMonthlyPrice = (listing, context, translations, supportsMonthlyPayment, teaserRate) => {
  const shouldGetMonthlyPrice = showMonthlyPrice(listing, supportsMonthlyPayment, teaserRate) && showPriceCalculation(listing, context);
  if (shouldGetMonthlyPrice) {
    const {t, msgsMonthlyPayment, messages, priceSuffix} = translations;
    const resolvedMonthlyPrice = resolveMonthlyPrice(listing, teaserRate);
    const suffix = definedValue(priceSuffix, '');
    return {
      text: `${t(msgsMonthlyPayment.currencyCode)} $${resolvedMonthlyPrice}/${t(msgsMonthlyPayment.monthAbbreviation)}${suffix}`,
      tooltip: {
        content: t(messages.boatLoansContent.monthlyPaymentTooltip.text, {
          term: calculateTerm(listing),
          teaserRate
        }),
        title: `$${resolvedMonthlyPrice}/${t(msgsMonthlyPayment.month)}`
      }
    };
  }
  return undefined;
};

export const getSellerLocation = (listing) => {
  const location = getLocation(listing) || '';
  const sellerLocation = location ? ' | ' + location : '';
  return sellerLocation;
};

export const getListingOwnerLogo = (listing) => {
  const theLogo = listing?.owner?.logos?.enhanced || listing?.owner?.logos?.default || '';
  const logoHttps = theLogo.replace(/^http:\/\//, 'https://');
  const logoUrl = setImageUrl({
    imageUrl: logoHttps,
    resizerImageParams: {
      width: LOGO_SIZES.listing.w,
      height: LOGO_SIZES.listing.h
    }
  });
  return logoUrl;
};

export const basicParsedListing = (listing, makeDependencies, actions, config, translations, supportsMonthlyPayment, teaserRate) => {
  const {defaultType, tracking } = makeDependencies;
  const {trackClick} = actions;
  listing.onClick = trackClick(listing.id, defaultType, tracking?.region, listing.make);
  listing.tracking = tracking;
  const monthlyPrice = findMonthlyPrice(listing, config, translations, supportsMonthlyPayment, teaserRate);
  if (monthlyPrice) {
    listing.monthlyPrice = monthlyPrice;
  }
  return listing;
};

export const makeBasicThreeColumnListingProps = (listing, config, makeDependencies, propFlags, priceProps, actions) => {
  const location = getLocation(listing) || '';
  const DEFAULT_LISTING_IMAGE = getAssetUrl( '/assets/images/default-listing-image.svg');
  const { position, translations, defaultType, device, tracking, locale } = makeDependencies;
  const { currency, teaserRate, supportsMonthlyPayment } = priceProps;
  const { enableListingImageCarousel, isBranded } = propFlags;
  const {trackClick} = actions;
  const sellerLocation = getSellerLocation(listing);
  const props = {
    tags: getTags(listing),
    name: getTitle(listing),
    image: getImageUrl(listing, defaultType) || DEFAULT_LISTING_IMAGE,
    imageAlt: getImageAlt(listing, config),
    imageFetchPriority: position <= 6 ? true : false,
    price: findListingPrice(listing, currency),
    onClick: trackClick(listing.id, defaultType, tracking?.region, listing.make),
    // hardcoded Price Drop for YW!!!!
    prevPrice: showPrevPrice(listing) ? '↓ Price Drop' : undefined,
    location,
    seller: getSellerName(listing.owner) + sellerLocation,
    monthlyPrice: findMonthlyPrice(listing, config, translations, supportsMonthlyPayment, teaserRate),
    type: defaultType,
    tracking,
    defaultImage: DEFAULT_LISTING_IMAGE,
    listing,
    ...getCPYBDataForListing(listing, locale),
    ...setCarouselProps(listing, device, enableListingImageCarousel, isBranded)
  };

  return props;
};

export const buildBasicThreeColumnListingProps = (listing, config, makeDependencies, propFlags, priceProps, actions) => {
  if (listing.ssrParsed) {
    const { translations } = makeDependencies;
    const { teaserRate, supportsMonthlyPayment } = priceProps;
    return basicParsedListing(listing, makeDependencies, actions, config, translations, supportsMonthlyPayment, teaserRate);
  }
  return makeBasicThreeColumnListingProps(listing, config, makeDependencies, propFlags, priceProps, actions);
};

export const makeSponsoredBoatsProps = (listing, config, listingType, makeDependencies, propFlags, priceProps, actions) => {
  const mainProps = buildBasicThreeColumnListingProps(listing, config, makeDependencies, propFlags, priceProps, actions);
  const {isExactMatchSponsored} = propFlags;
  const {currency} = priceProps;
  const sellerLocation = getSellerLocation(listing);
  const sponsoredProps = {
    ...mainProps,
    tags: [{ text: isExactMatchSponsored ? 'Featured' : 'Sponsored' }],
    logo: getListingOwnerLogo(listing) || undefined,
    logoAlt: listing?.owner?.name ?? '',
    seller: (listing?.owner?.name ?? '') + sellerLocation,
    classNames: { sponsored: true },
    type: listingType,
    price: getPriceV2(listing, null, currency),
    prevPrice: undefined,
    monthlyPrice: undefined,
  };

  return sponsoredProps;
};

export const makeEnhancedBoatsProps = (listing, listingType) => {
  const enhancedProps = {
    logo: getListingOwnerLogo(listing) || undefined,
    logoAlt: listing?.owner?.name ?? '',
    type: listingType,
  };
  return enhancedProps;
};

export const makeOemListingProps = (listing, listingType, translations) => {
  const {t, messages} = translations;
  const sellerName = `${getSellerName(listing.owner)} | ${t(messages.dealerContact.manufacturerListing)}`;

  const oemProps = {
    type: listingType,
    logo: getListingOwnerLogo(listing) || undefined,
    logoAlt: listing?.owner?.name ?? '',
    hiddenPriceMessage: t(messages.pricing.request),
    manufacturerListingText: t(messages.dealerContact.manufacturerListing),
    seller: sellerName
  };
  return oemProps;
};

export const oemParsedListing = (listing, translations, defaultType, tracking) => {
  const {t, messages} = translations;
  const oemProps = {
    tracking,
    hiddenPriceMessage: t(messages.pricing.request),
    manufacturerListingText: t(messages.dealerContact.manufacturerListing),
    type: defaultType
  };
  return oemProps;
};

export const buildOemListingProps = (listing, config, actions, makeDependencies, propFlags, priceProps) => {
  const {translations, tracking, defaultType} = makeDependencies;
  const {trackClick} = actions;
  const oemProps = buildBasicThreeColumnListingProps(listing, config, makeDependencies, propFlags, priceProps, actions);

  const oemCustomProps = listing.ssrParsed ? oemParsedListing(listing, translations, defaultType, tracking) : makeOemListingProps(listing, defaultType, translations);
  const doTrackClick = trackClick(listing.id, defaultType, tracking?.region, listing.make);
  oemProps.onClick = (event) => {
    doTrackClick(event);
    window.open(getBoatUrl(listing), '_self');
  };
  return {...oemProps, ...oemCustomProps};
};

export const makeEnhancedListingProps = (listing, config, actions, makeDependencies, propFlags, priceProps) => {
  const {isExactMatchSponsored, hideRequestInfoButton} = propFlags;
  const {defaultType, translations} = makeDependencies;
  const {onOpenContact, trackClick} = actions;
  const enhancedProps = buildBasicThreeColumnListingProps(listing, config, makeDependencies, propFlags, priceProps, actions);
  const {t, messages} = translations;
  const extraProps = makeEnhancedBoatsProps(listing, defaultType, isExactMatchSponsored);
  const listingContactButton = {
    onClick: (event) => {
      onOpenContact(event, listing);
    },
    text: t(messages.searchResults.listing.requestInfo)
  };
  enhancedProps.onClick = trackClick(listing.id, defaultType, makeDependencies.tracking?.region, listing.make);
  enhancedProps.contact = hideRequestInfoButton ? {} : listingContactButton;
  return {...enhancedProps, ...extraProps};
};

export const enhancedParsedListing = (listing, makeDependencies, actions, propFlags) => {
  const {defaultType, translations, tracking} = makeDependencies;
  const {onOpenContact, trackClick} = actions;
  const {hideRequestInfoButton} = propFlags;
  const {t, messages} = translations;
  const listingContactButton = {
    onClick: (event) => {
      onOpenContact(event, listing);
    },
    text: t(messages.searchResults.listing.requestInfo)
  };
  listing.onClick = trackClick(listing.id, defaultType, makeDependencies.tracking?.region, listing.make);
  listing.contact = hideRequestInfoButton ? {} : listingContactButton;
  listing.type = defaultType;
  listing.tracking = tracking;
  return listing;
};

export const buildEnhancedListingProps = (listing, config, actions, makeDependencies, propFlags, priceProps) => {
  if (listing.ssrParsed) {
    listing = buildBasicThreeColumnListingProps(listing, config, makeDependencies, propFlags, priceProps, actions);
    return enhancedParsedListing(listing, makeDependencies, actions, propFlags);
  }
  return makeEnhancedListingProps(listing, config, actions, makeDependencies, propFlags, priceProps);
};

export const buildSponsoredListingProps = (listing, config, actions, makeDependencies, propFlags, priceProps) => {
  const {isExactMatchSponsored, hideRequestInfoButton} = propFlags;
  const {onClickRequestInfo, trackClick} = actions;
  const {translations, tracking, defaultType} = makeDependencies;
  if (listing.ssrParsed) {
    listing.type = defaultType;
    listing.tracking = tracking;
  }
  const {t, messages} = translations;
  const listingContactButton = {
    onClick: (event) => {
      onClickRequestInfo(event, isExactMatchSponsored, listing);
    },
    text: t(messages.searchResults.listing.requestInfo)
  };
  const sponsoredBoatsProps = listing.ssrParsed ? listing :  makeSponsoredBoatsProps(listing, config, defaultType, makeDependencies, propFlags, priceProps, actions);
  sponsoredBoatsProps.onClick = trackClick(listing.id, defaultType, tracking?.region, listing.make);
  sponsoredBoatsProps.contact = hideRequestInfoButton ? {} : listingContactButton;
  return sponsoredBoatsProps;
};

export const listingIsEnhanced = (listing, enhancedType) => {
  return !!listing?.featureType?.enhanced || (enhancedType && listing.elementType === enhancedType);
};

export const listingIsOemModel = (listing, oemType) => {
  return !!listing?.isOemModel || (oemType && listing.elementType === oemType);
};

// We add here stuff we need from listings to be in the dom to avoid duplication
// This is ugly, but even uglier is to store everything in the window.__REDUX_STATE__ and having content twice
export const makeDomMetadata = (listing, context) => {
  const nominalLenght = getListingLength(listing, context);
  const subdivision = getListingSubdivision(listing);
  const price = getPriceAmount(listing);
  return `${listing.make}|${listing.class}|${nominalLenght}|${subdivision}|${price}`;
};

export const listingInNewTab = (context, device) => {
  const isTargetBlankEnabled = !!context?.supports?.isTargetBlankEnabled;
  const shouldOpenInNewTab = device === BREAKPOINTS.desktop && isTargetBlankEnabled;
  return shouldOpenInNewTab;
};
