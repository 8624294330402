import React from 'react';
import {BREAKPOINTS} from '../../../../../utils/commonHelper';
import PropTypes from 'prop-types';
import { HorizontalGallery } from '@dmm/lib-react-ui-components';
import {getMessages} from '../../../../../tppServices/translations/messages';
import {setGenericEvent} from '../../../../../store/actions/dataLayer';
import {useDispatch} from 'react-redux';
import {FUZZY_SPONSORED_CAROUSEL, THREE_COL_LISTINGS_CONTAINER} from '../../../../../utils/domParserHelper';


const useScrollDirectionHandler = () => {
  const dispatch = useDispatch();
  return (direction) => {
    dispatch(setGenericEvent('sponsored carousel event', `carousel scroll ${direction}`, `scroll ${direction}`, '', 'scroll'));
  };
};

const ListingsWithCarrousel = ({ device, formatMessage, firstChunk, secondChunk, sponsoredListingCarouselChunk }) => {
  const messages = getMessages();
  const handleScrollDirection = useScrollDirectionHandler();
  const t = formatMessage;
  return (
    <>
      <div className="container" data-testid={THREE_COL_LISTINGS_CONTAINER}>
        {firstChunk}
      </div>
      <div className="container carousel" data-testid={FUZZY_SPONSORED_CAROUSEL}>
        <HorizontalGallery
          title={t(messages.searchResults.listingsCarrousel.sponsored.title)}
          subtitle={t(messages.searchResults.listingsCarrousel.sponsored.subtitle)}
          hideArrowButtons={device === BREAKPOINTS.mobile}
          leftButtonAttributes={{
            'data-reporting-click-internal-link-type': 'click',
            'data-reporting-click-internal-link-id': 'move carousel left'
          }}
          rightButtonAttributes={{
            'data-reporting-click-internal-link-type': 'click',
            'data-reporting-click-internal-link-id': 'move carousel right'
          }}
          {...(device === BREAKPOINTS.mobile && {onScrollDirection: handleScrollDirection})}
        >
          {sponsoredListingCarouselChunk}
        </HorizontalGallery>
      </div>
      <div className="container" data-testid={THREE_COL_LISTINGS_CONTAINER}>
        {secondChunk}
      </div>
    </>
  );
};

ListingsWithCarrousel.propTypes = {
  device: PropTypes.string,
  formatMessage: PropTypes.func,
  firstChunk: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  secondChunk: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  sponsoredListingCarouselChunk: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export {ListingsWithCarrousel};
