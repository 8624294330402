import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getH1 } from '../../../SearchResults/utils/meta';
import DescriptionBox from '../../../../components/DescriptionBox';
import DealerContact from '../../../../components/DealerContact';
import { getCurrentLocale } from '../../../../utils/language';
import './styles.css';
import {languagesEnglish} from '../../../../tppServices/translations/languageConstants';
import { setImageUrl, LOGO_SIZES } from '../../../../utils/listingHelper';
import ImageWithErrorHandler from '../../../../components/ImageWithErrorHandler';

class BrandedHeader extends PureComponent {

  filterDescription(description, multiLangDescriptions, languagesEnglish) {
    let locale = getCurrentLocale();
    if (multiLangDescriptions[`${languagesEnglish[locale]}Description`]) {
      return multiLangDescriptions[`${languagesEnglish[locale]}Description`];
    }

    return description;
  }

  render() {
    const { bannerPath, description, multiLangDescriptions,
      logoPath, logoUrl, displayName, seoParams, portal, address } = this.props;

    const logo = logoPath ? `https:${encodeURI(logoPath)}` : logoUrl;
    const logoImageUrl = setImageUrl({
      imageUrl: logo,
      resizerImageParams: {
        width: LOGO_SIZES.broker.w,
        height: LOGO_SIZES.broker.h
      }
    });

    // Render current locale description
    const langDescription = this.filterDescription(description, multiLangDescriptions, languagesEnglish);

    return (
      <div className="branded-header">
        <div className={'logo-container'}>
          {
            logoImageUrl &&
            <div className="logo-frame">
              <ImageWithErrorHandler
                className="logo"
                src={logoImageUrl}
                alt={'logo'}
                noFallbackImage={true}
              />
            </div>
          }
          { bannerPath &&
            <div className="banner">
              <div className="banner-logo" style={{ backgroundImage: `url(${encodeURI(bannerPath)})` }} />
            </div>
          }
        </div>

        { !!logoPath && <DealerContact /> }
        { (displayName || langDescription) &&
          <div className={classnames('information-container', {'no-logo': (!logoPath)})}>
            <div className={classnames({'title-container': (!logoPath), 'no-description': !langDescription})}>
              { displayName &&
                <h1>{getH1({params: seoParams, portal, isBranded: true, displayName})}</h1>}
              { (!logoPath) && <DealerContact classOverride={{title: true}} /> }
            </div>
            { address && <DescriptionBox dataName="party-description" data={address} /> }
            { langDescription && <DescriptionBox dataName="party-description" showMore data={langDescription} small /> }
          </div>
        }
      </div>
    );
  }
}

BrandedHeader.defaultProps = {
  bannerPath: '',
  description: '',
  multiLangDescriptions: {},
  logoPath: '',
  logoUrl: '',
  name: '',
  seoParams: {},
  portal: '',
  params: '',
  address: ''
};

BrandedHeader.propTypes = {
  bannerPath: PropTypes.string,
  description: PropTypes.string,
  multiLangDescriptions: PropTypes.shape(),
  logoPath: PropTypes.string,
  logoUrl: PropTypes.string,
  displayName: PropTypes.string,
  seoParams: PropTypes.object,
  portal: PropTypes.string,
  params: PropTypes.string,
  address: PropTypes.string

};

export default BrandedHeader;
