import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {PortalConfigContext} from '../../../../../config/portal';
import { getBoatUrl } from '../../../../../utils/urlHelpers/boat';
import {
  ListingStandard,
  ListingEnhanced,
  ListingSponsored,
  ListingOem,
  ListingCarouselCard
} from '@dmm/lib-react-ui-components';
import Link from '../../../../../components/SEO/Link';
import { PRODUCT_CATEGORY } from '../../../../../constants/dataLayer';

import {useTPPServices} from '../../../../../tppServices/tppDIHooks';
import {SSR_METADATA} from '../../../../../utils/domParserHelper';
import {makeDomMetadata} from '../../../../../utils/propsBuilder/listingPropsBuilder';

export const ThreeColumnListing = ({
  position,
  type,
  tracking,
  listing,
  ...props
}) => {
  const {boatsConstants} = useTPPServices();
  const context = useContext(PortalConfigContext);

  if (props.ssrParsed && !listing) {
    listing = props;
  }
  if (!listing) {
    return null;
  }
  const {
    LISTING_SPONSORED,
    LISTING_ENHANCED,
    LISTING_STANDARD,
    LISTING_MANUFACTURER,
    SPONSORED_CAROUSEL_LISTING
  } = boatsConstants;
  useEffect(() => {
    if (tracking && position && listing) {
      const { region, setProductImpression, page } = tracking;
      setProductImpression(
        listing.id,
        type,
        position,
        page,
        PRODUCT_CATEGORY.BOAT,
        null,
        region,
        listing.model,
        props.name,
        listing.make
      );
    }

    const supportsPermutive = context?.supports?.permutive || false;
    if (supportsPermutive && window?.permutiveHelper?.addListing) {
      window.permutiveHelper.addListing(listing);
    }
  }, [
    tracking?.region,
    tracking?.page,
    position,
    type,
    listing?.id,
    listing?.make,
    listing?.model,
    props.name
  ]);

  const getListingComponent = (props) => {
    switch (type) {
      case LISTING_SPONSORED:
        return <ListingSponsored {...props} />;

      case LISTING_ENHANCED:
        return <ListingEnhanced {...props} />;

      case LISTING_MANUFACTURER: {
        return <ListingOem {...props} />;
      }


      case LISTING_STANDARD:
      default:
        return <ListingStandard {...props} />;
    }
  };

  const getHyperlinkAttributes = (definedType = type) => {
    return {
      href: getBoatUrl(listing),
      'data-reporting-click-product-id': listing.id,
      'data-reporting-click-listing-type': definedType,
      'data-reporting-rank': position,
      'data-reporting-page': tracking?.page || 0,
      // add metadata for SSR parsing
      [SSR_METADATA]: makeDomMetadata(listing, context),
      ...(props.targetBlank && {
        target: '_blank',
        rel: 'noopener noreferrer'
      })
    };
  };

  switch (type) {
    case SPONSORED_CAROUSEL_LISTING:
      return <ListingCarouselCard {...props}
        hyperlinkAttributes={getHyperlinkAttributes(LISTING_SPONSORED)}
        sellerMarketingMessage={props.seller}
      />;

    default:
      return (
        <Link {...getHyperlinkAttributes()}>
          {getListingComponent(props)}
        </Link>
      );
  }
};

ThreeColumnListing.propTypes = {
  position: PropTypes.number,
  type: PropTypes.string,
  listing: PropTypes.object,
  ssrParsedListing: PropTypes.object,
  name: PropTypes.string,
  seller: PropTypes.string,
  tracking: PropTypes.shape({
    region: PropTypes.string,
    page: PropTypes.string,
    pageSize: PropTypes.string,
    setProductImpression: PropTypes.func
  }),
  targetBlank: PropTypes.bool,
  ssrParsed: PropTypes.bool
};
