import React, { PureComponent, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { frontloadConnect } from 'react-frontload';
import get from 'lodash/get';
import head from 'lodash/head';
import { withCookies, Cookies } from 'react-cookie';
import queryString from 'query-string';
import MainContentV1 from './MainContent';
import MainContentV2 from './MainContentV2';
import DefaultLayout from '../../layouts/Default';
import * as actions from '../../store/actions';
import * as tridentActions from '../../store/actions/tridentApi';
import * as kevelActions from '../../store/actions/kevelApi';
import ErrorMessage from '../../components/ErrorMessage';
import * as utils from '../../store/utils';
import {
  generateSearchPath,
  getDefaultParams,
  parseSearchParams,
  reduceURLForRedirect,
  redirectPartialMakeNameToFullMakeName
} from '../../utils/urlHelpers/boats';
import {
  typeClassRedirectUrl,
  makeModelRedirectUrl,
  locationRedirectUrl,
  getLocationRedirects,
  nonExpectedUrlsRedirect
} from '../../utils/urlHelpers/redirects';
import { getActiveParams } from '../../utils/urlHelpers/boats';
import { getParamsFromLocationAndHistoryQueryString } from '../../utils/featureToggles';
import { REDUCTION_REDIRECT_COOKIE } from '../../constants/cookies';
import { PortalConfigContext, withPortalConfigContext } from '../../config/portal';
import { validateSubdivisionCode } from './utils/subdivisionsCodeValidator';
import { runOnce } from '../../utils/runOnceHelper';

import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { FEATURE_FLAG_KEYS, isFeatureFlagActive, withABTestContext } from '../../context/ABTestContext';
import { setCookie } from '../../utils/cookies';
import {injectIntl} from 'react-intl';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';
import {getCommonBoatParsers} from '../../utils/urlHelpers/boatsConstantsParsers';
import { getMessages } from '../../tppServices/translations/messages';

class SearchResults extends PureComponent {
  constructor(props) {
    super(props);
    const hasNoListings = this.props.search.count < 1;
    const routeConstants = getRouteConstantsFromI18n();
    const isNotRoot =
      `${this.props.location.pathname}/` !== routeConstants.SEARCH_URL_ROOT;
    const {parsePageParams} = getCommonBoatParsers(null, this.context);
    this.parsePageParams = parsePageParams;
    if (
      utils.isServer() &&
      isNotRoot &&
      this.props.success &&
      (hasNoListings || !this.isPageVisible())
    ) {
      const { cookies } = this.props;
      setCookie(cookies, REDUCTION_REDIRECT_COOKIE, true);
    }
    this.messages = getMessages();
  }

  isPageVisible() {
    const pageSize = parseInt(get(this.props, 'params.pageSize', '28'));
    const maxVisiblePage = Math.ceil(this.props.search.count / pageSize);
    const pageParam = get(this.props, 'match.params.page', '');

    const { page } = this.parsePageParams(pageParam);

    return page <= maxVisiblePage;
  }

  getLocationRedirect() {
    const locationMaps = getLocationRedirects();
    const {
      location: { pathname }
    } = this.props;
    const countryParam = get(this.props, 'match.params.country');

    let locationDestination;
    locationMaps.find((redirectMap) => {
      redirectMap.origin.find((originEntry) => {
        if (
          !locationDestination &&
          !countryParam &&
          pathname?.includes(originEntry)
        ) {
          locationDestination = pathname.replace(
            originEntry,
            redirectMap.destination
          );
        }
      });
    });

    return locationDestination;
  }

  getUrlRedirect = (props) => {
    const messages = this.messages;
    const t = this.props.intl.formatMessage;
    const makeModel = head(get(props, 'facets.makeModel', []));
    const makeUrl = get(props, 'match.params.make', '');
    const fullMakeNameUrl = makeModel
      ? `${t(messages.search.make)}-${normalizeString(makeModel.value)}`
      : '';
    return makeUrl !== fullMakeNameUrl && makeModel
      ? redirectPartialMakeNameToFullMakeName(props, fullMakeNameUrl)
      : reduceURLForRedirect(props);
  };

  render() {
    const routeConstants = getRouteConstantsFromI18n();
    const {
      abTestContext,
      cookies,
      location: { pathname, search }
    } = this.props;
    const t = this.props.intl.formatMessage;
    const messages = this.messages;
    let hasNoListings = this.props.search.count < 1;
    let isNotRoot = `${pathname}/` !== routeConstants.SEARCH_URL_ROOT;
    const isLowerCase = pathname && pathname === pathname.toLowerCase();
    const urlMatchParams = getActiveParams(get(this.props, 'match.params', {}));
    let locationRedirect = this.getLocationRedirect();

    if (utils.isServer() && this.props.success && locationRedirect) {
      return <Redirect to={{ pathname: locationRedirect }} {...this.props} />;
    }

    if (utils.isServer() && isNotRoot && hasNoListings && this.props.success) {
      return (
        <Redirect
          to={{ pathname: this.getUrlRedirect(this.props) }}
          {...this.props}
        />
      );
    }

    if (utils.isServer() && this.props.success && !isLowerCase) {
      return (
        <Redirect to={{ pathname: pathname.toLowerCase() }} {...this.props} />
      );
    }

    const redirectUrlsWithNonExistantFacets = nonExpectedUrlsRedirect(
      urlMatchParams,
      this.props.facets
    );
    if (redirectUrlsWithNonExistantFacets) {
      return (
        <Redirect
          to={{ pathname: redirectUrlsWithNonExistantFacets }}
          {...this.props}
        />
      );
    }

    const typeClassRedirect = typeClassRedirectUrl(urlMatchParams);
    if (typeClassRedirect) {
      return <Redirect to={{ pathname: typeClassRedirect }} {...this.props} />;
    }

    const makeModelRedirect = makeModelRedirectUrl(urlMatchParams);
    if (makeModelRedirect) {
      const rootSRPRedirect = generateSearchPath(undefined, undefined, true);
      return <Redirect to={{ pathname: rootSRPRedirect }} {...this.props} />;
    }

    if (
      urlMatchParams.city ||
      urlMatchParams.subdivision ||
      urlMatchParams.region
    ) {
      if (this.props?.search?.records?.length) {
        const locationRedirectURL = locationRedirectUrl(
          this.props.search.records[0],
          this.props,
          urlMatchParams
        );
        if (locationRedirectURL) {
          return (
            <Redirect to={{ pathname: locationRedirectURL }} {...this.props} />
          );
        }
      } else if (!urlMatchParams.country && urlMatchParams.region) {
        const rootSRPRedirect = generateSearchPath(undefined, undefined, true);
        return <Redirect to={{ pathname: rootSRPRedirect }} {...this.props} />;
      }
    }

    const regexMakeDoubleHyphen = new RegExp(
      `${t(messages.search.make)}-([^-]+)(--+)([^-]+)`
    );
    if (utils.isServer() && isNotRoot && regexMakeDoubleHyphen.test(pathname)) {
      const groups = regexMakeDoubleHyphen.exec(pathname);
      // Redirect makes with two or more hyphens on its name to the same make with a single hyphen
      const newPath = pathname.replace(
        groups[0],
        `${t(messages.search.make)}-${groups[1]}-${groups[3]}`
      );
      return <Redirect to={newPath} {...this.props} />;
    }

    if (utils.isServer() && get(this.context, 'supports.ywBrokerRedirect')) {
      const params = queryString.parse(search);
      if (params.listingId) {
        return (
          <Redirect
            to={{
              pathname: `/${t(messages.searchRoot)}/brokerRedirect/${
                params.listingId
              }/`
            }}
            {...this.props}
          />
        );
      }
    }

    const defaultRadiusAbTestEnabled =
    get(this.context, 'supports.radiusSearch.enabled', false) &&
    isFeatureFlagActive(
      FEATURE_FLAG_KEYS.DEFAULT_RADIUS,
      abTestContext?.featureFlags,
      cookies
    );

    if (utils.isServer() && !isNotRoot && defaultRadiusAbTestEnabled) {
      return (
        <Redirect
          to={{
            pathname: `/${t(messages.searchRoot)}/${t(messages.search.radius)}-200/`
          }}
          {...this.props}
        />
      );
    }

    const pageParam = get(this.props, 'match.params.page', '');
    const maxPages = get(
      this.context,
      'pages.searchResults.pagination.maxPages',
      357
    );

    const { page } = this.parsePageParams(pageParam);
    if (page > maxPages) {
      return (
        <DefaultLayout {...this.props} pageType="SearchResults">
          <ErrorMessage {...this.props} message="404" debug={'from search results'}/>
        </DefaultLayout>
      );
    }

    if (
      utils.isServer() &&
      isNotRoot &&
      this.props.success &&
      !this.isPageVisible()
    ) {
      const params = get(this.props, 'match.params', {});
      const redirectUrl = generateSearchPath(
        {},
        getDefaultParams(params),
        true
      );
      return <Redirect to={{ pathname: redirectUrl }} {...this.props} />;
    }

    const subdivisionUrlParam = get(
      this.props,
      'match.params.subdivision',
      null
    );
    if (subdivisionUrlParam) {
      if (validateSubdivisionCode(subdivisionUrlParam)) {
        const params = get(this.props, 'match.params', {});
        const redirectUrl = generateSearchPath(
          {},
          getDefaultParams(params),
          true
        );
        return <Redirect to={{ pathname: redirectUrl }} {...this.props} />;
      }
    }

    const brandID = get(this.props, 'brandID');
    const pageType = brandID ? 'BrandedOEMSearchResults' : 'SearchResults';
    return (
      <DefaultLayout {...this.props} pageType={pageType} brandID={brandID}>
        {this.props.errors ? (
          <ErrorMessage {...this.props} debug={'from search results 2'}/>
        ) : (
          <MainContent {...this.props} />
        )}
      </DefaultLayout>
    );
  }
}

const MainContent = (props) => {
  const context = useContext(PortalConfigContext);
  const useThreeColumnLayout = get(
    context,
    'supports.threeColumnLayout',
    false
  );
  const Content = useThreeColumnLayout ? MainContentV2 : MainContentV1;

  const newProps = {
    ...props,
    isThreeColumnLayout: useThreeColumnLayout
  };
  return <Content {...newProps} />;
};

MainContent.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  abTestContext: PropTypes.shape({
    featureFlags: PropTypes.array
  })
};

const mapStateToProps = (state) => {
  const teaserRate = get(state.app, 'trident.rates[0].teaserRate');
  return {
    params: get(state.app, 'params', {}),
    search: get(state.app, 'data.search', {}),
    interestingInformation: get(state.app, 'data.interestingInformation', {}),
    wordsmithContent: get(state.app, 'data.wordsmithContent'),
    relatedBoatArticles: get(state.app, 'data.relatedBoatArticles', []),
    videos: get(state.app, 'data.videos', []),
    sponsored: get(state.app, 'data.sponsored', {}),
    fuzzySponsoredBoats: get(state.app, 'data.fuzzySponsoredBoats', {}),
    facets: get(state.app, 'data.facets', {}),
    premiumPlacementAd: get(state.app, 'data.premiumPlacementAd', {}),
    seoMakeInfo: get(state.app, 'data.seoMakeInfo'),
    similarBoatsForOEMs: get(state.app, 'data.similarBoatsForOEMs', false),
    similarListings: get(state.app, 'data.similarListings', {}),
    OEMDetails: get(state.app, 'data.OEMDetails', {}),
    isWorking: state.app.isWorking,
    componentWorking: state.app.componentWorking,
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode,
    brandID: get(state.app, 'data.brandShowcase.brandId'),
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false),
    tridentTeaserRate: parseFloat(teaserRate?.replace(/[%, ]/g, '')),
    ficoScores: tridentActions.getFicoScores(
      get(state.app, 'trident.rates[0].rates'),
      []
    )
  };
};

SearchResults.contextType = PortalConfigContext;

SearchResults.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  errors: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  search: PropTypes.shape({
    count: PropTypes.number,
    records: PropTypes.array
  }).isRequired,
  facets: PropTypes.object,
  success: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  tridentTeaserRate: PropTypes.number,
  abTestContext: PropTypes.shape({
    featureFlags: PropTypes.array
  })
};

// we create a function that will run only once bypassing multiple renders.
const justFirstTime = runOnce();

const loadSearchResultsData = async ({
  dispatch,
  history,
  location,
  cookies,
  debug = process.env.REACT_APP_LOCAL_DEBUG,
  tridentTeaserRate,
  abTestContext,
  configContext,
}) => {
  const otherParams = getParamsFromLocationAndHistoryQueryString({
    location,
    history
  });

  if (
    utils.isServer() ||
    actions.shouldGetData(location) ||
    (justFirstTime() && debug)
  ) {
    const promises = [
      dispatch(
        actions.getData(
          location.pathname,
          cookies.cookies,
          otherParams,
          abTestContext
        )
      )
    ];
    if (!tridentTeaserRate) {
      promises.push(dispatch(tridentActions.getRatesFromTridentAPI()));
    }

    const isKevelAdEnabled = get(configContext, 'supports.isKevelAdEnabled', false) &&
      isFeatureFlagActive(FEATURE_FLAG_KEYS.KEVEL_ADS, abTestContext?.featureFlags, cookies
    );

    if (isKevelAdEnabled) {
      const params = parseSearchParams(location.pathname);
      promises.push(dispatch(kevelActions.getAd(cookies, params)));
    }

    return await Promise.allSettled(promises);
  }
};

const SearchResultsWithDataLoaded = frontloadConnect(loadSearchResultsData, {
  onUpdate: true,
  onMount: true
})(SearchResults);
const SearchResultsWithRedux = connect(
  mapStateToProps,
  null
)(SearchResultsWithDataLoaded);
const SearchResultsWithCookies = withCookies(SearchResultsWithRedux);
const SearchResultsWithABTest = withABTestContext(SearchResultsWithCookies);
const SearchResultsWithPortalConfig = withPortalConfigContext(SearchResultsWithABTest);

export default injectIntl(SearchResultsWithPortalConfig);
